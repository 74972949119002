import { z } from 'zod';

/** Employee permissions dto schema. */
export const employeePermissionsDtoSchema = z.object({
	can_view: z.number(),
	can_edit: z.number(),
});

/** Employee permissions dto. */
export type EmployeePermissionsDto = Readonly<z.infer<typeof employeePermissionsDtoSchema>>;
